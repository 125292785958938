<template>
  <header class="flex flex-col md:flex-row justify-between items-center py-3 container">
    <div class="flex justify-between w-full md:w-auto">
      <NuxtLink to="/"><img alt="Vue logo" class="w-16 h-auto" src="/img/logo.svg" /></NuxtLink>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        class="inline-flex items-center p-2 md:hidden focus:outline-none"
        :class="dark ? 'text-white' : 'text-neutral-800'"
        aria-controls="navbar-default"
        aria-expanded="false"
        @click="toggleMenu"
      >
        <UIcon name="i-heroicons-bars-2" class="size-6" />
      </button>
    </div>

    <nav
      id="navbar-default"
      class="flex flex-col md:space-x-4 self-start md:self-center mt-6 md:mt-0 md:flex-row items-start justify-between"
      :class="{ hidden: itemsHidden, 'bg-white rounded-lg absolute z-30 !mt-24 top-0 left-0 right-0 grow p-4 mx-4 shadow-xl': isMobile }"
    >
      <NuxtLink
        to="/ticket"
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="hideMenu"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        v-if="!isAuthenticated"
        >Tickets</NuxtLink
      >
      <NuxtLink
        to="/lineup"
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="hideMenu"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        >Line Up</NuxtLink
      >
      <NuxtLink
        to="/kulturstadel"
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="hideMenu"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        >Kulturstadel</NuxtLink
      >
      <NuxtLink
        to="/faqs"
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="hideMenu"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        >FAQs</NuxtLink
      >
      <NuxtLink
        to="/login"
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="hideMenu"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        v-if="!isAuthenticated"
        >Login</NuxtLink
      >
      <NuxtLink
      to="/admin"
      class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
      @click="hideMenu"
      :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
      v-if="isAuthenticated && userStore.user?.role.id == 1"
      >Admin</NuxtLink
      >
      <NuxtLink
      to="/benutzer"
      class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
      @click="hideMenu"
      :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
      v-if="isAuthenticated && userStore.user?.role.id == 2"
      >Mein Bereich</NuxtLink
      >
      <button
        class="uppercase px-4 py-2 rounded-md opacity-75 hover:opacity-100 hover:bg-brightestGreen hover:text-darkestGreen"
        @click="logout"
        :class="dark && !isMobile ? 'text-white' : 'text-neutral-800'"
        v-if="isAuthenticated"
      >
        Ausloggen
      </button>
    </nav>
  </header>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    dark?: boolean
  }>(),
  {
    dark: false,
  },
)

const userStore = useUserStore()

const itemsHidden = ref(true)
const { isAuthenticated } = storeToRefs(userStore)
const isMobile = ref(false)

const onWidthChange = () => {
  if (window.innerWidth > 768) {
    itemsHidden.value = false
    isMobile.value = false
  } else {
    itemsHidden.value = true
    isMobile.value = true
  }
}

onMounted(() => {
  window.addEventListener('resize', onWidthChange)

  onWidthChange()
})
onUnmounted(() => window.removeEventListener('resize', onWidthChange))

function toggleMenu() {
  itemsHidden.value = !itemsHidden.value
}

// Hides the menu after each click on a link
const hideMenu = () => {
  if (window.innerWidth < 768) {
    itemsHidden.value = true
  }
}

// Logout the user
async function logout() {
  userStore.logoutUser()
  await navigateTo('/login')
  // Hide Menu if necessary
  hideMenu()
}
</script>

<style scoped></style>
